:root {
	--boxColor: rgba(0, 255, 242, 0.836);
	--rotateSpeed: 2s;
	--bounceSpeed: 2s;
	--boxSize:1em
}

.Bounce {
    position: relative;
	height: 45vh;
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	flex-direction: column;
	margin-right: .5em;
	perspective: 10em;
	perspective-origin: 50% calc(50% - 3em);
	

	.texts{
		position: absolute;
		left:0;
		width: 100%;
		z-index: 2;
		height: 100%;
		transform-style: preserve-3d;

		h4{
			position: absolute;
			font-size: 11px;
			left: 79%;
			top:55%;
			border: 1px solid;
			opacity: 0;
			transform: rotateY(60deg) rotateX(90deg);
			padding: 2px 8px;
			border-radius: 3px;
			animation:  dropDown 10s infinite ease-out;

		}

		@for $i from 1 through 6{
			h4:nth-child(#{$i}){
				animation-delay:  calc(#{$i *2s});
				
			}
		}

		@keyframes dropDown {
			0%{
				opacity: .5;
				transform:  rotateY(60deg)rotateX(90deg);
			}

			5%{
				top:70%;
				left: 30%;
				transform:rotateY(0deg)rotateX(90deg);;
				font-size: 16px;
			}

			7%,23%{
				top:70%;
				left: 30%;
				transform: rotateY(0deg) rotateX(0);
				font-size: 16px;
			}

			30%,40%{
				top:52%;
				left:15%;
				transform:  rotateY(0deg);
				font-size: 25px;
			}

			50%,60%{
				top:35%;
				left:7%;
				transform: rotateY(0deg);
				font-size: 25px;
				opacity: 1;
			}

			70%,80%{
				top:20%;
				left:18%;
				transform: rotateY(0deg);
				font-size: 16px;
			}

			90%,95%{
				top:23%;
				left:25%;
				transform: rotateY(0deg);
				font-size: 16px;
			}


		}

	}

	


	.scene {
		transform-style: preserve-3d;
		animation: sceneRotate var(--rotateSpeed) infinite linear;
		position: absolute;
		top: 45%;
		left:100%;
		z-index: 1;
		transform: rotateY(60deg);

		@keyframes sceneRotate {
			0%,
			100% {
				transform: rotateY(30deg);
				
			}
			50% {
				transform: rotateY(60deg);
			}
		}
	}

	.ball {
		width: calc(var(--boxSize)/2);
		height: calc(var(--boxSize)/2);
		border-radius: 50%;
		background-color: lightblue;
		position: absolute;
		left: -.70em;
		bottom: 1em;
		background-image: radial-gradient(circle at top, lightblue, #000);
		animation: ballBounce var(--bounceSpeed) infinite ease-out,
			ballRotate var(--rotateSpeed) infinite linear reverse;

		@keyframes ballRotate {
			0%,
			100% {
				transform: rotateY(-30deg);
			}
			50% {
				transform: rotateY(-60deg);
			}
		}
		@keyframes ballBounce {
			0%,
			100% {
				bottom: -0.2em;
			}
			50% {
				bottom: 1em;
				animation-timing-function: ease-in;
			}
		}
	}

	.ballShadow {
		position: absolute;
		width: 100%;
		height: 100%;
		background-image: radial-gradient(#0007, #0000 50%);
		animation: ballShadow var(--bounceSpeed) infinite ease-out;

		@keyframes ballShadow {
			0%,
			8%,
			93.5%,
			100% {
				transform: scale(1);
				opacity: 1;
			}
			50% {
				transform: scale(1.5);
				opacity: 0.5;
				animation-timing-function: ease-in;
			}
		}
	}

	.cube {
		width: var(--boxSize);
		height: var(--boxSize);
		transform-style: preserve-3d;

		position: absolute;
		bottom: -1em;
		left: -1em;
		animation: cubeHeight var(--bounceSpeed) infinite linear;

		@keyframes cubeHeight {
			0%,
			100% {
				height: calc(var(--boxSize) - .3em);
			}
			6%,
			96% {
				height: var(--boxSize);
			}
		}

		.left,
		.right,
		.front,
		.back {
			display: flex;
			position: absolute;
			width: 100%;
			height: 100%;
			background: var(--boxColor);
			box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.667) inset;
		}

		.front {
			transform: translateZ(calc(var(--boxSize)/2));
			
		}
		.right {
			transform: rotateY(90deg) translateZ(calc(var(--boxSize)/2));
		}
		.back {
			transform: rotateY(180deg) translateZ(calc(var(--boxSize)/2));
		}
		.left {
			transform: rotateY(270deg) translateZ(calc(var(--boxSize)/2));
			background: transparent;
		}

		.top {
			position: absolute;
			width: var(--boxSize);
			height: var(--boxSize);
			background: var(--boxColor);
			transform: translateY(-50%) rotateX(90deg);
			box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.667) inset;
		}

		.bottom {
			position: absolute;
			width: var(--boxSize);
			height: var(--boxSize);
			background: rgba(45, 59, 59, 0.467);
			bottom: 0;
			transform: translateY(50%) rotateX(90deg);
			box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.667);
		} 
		
		.left {
			display: flex;
			align-items: center;
			justify-content: center;
			transform-style: preserve-3d;

            .inner-left,.inner-right{
                background: var(--boxColor);
                position: absolute;
                width: 20%;
                height: 100%;

            }
            .inner-left{
                left:0
            }
            .inner-right{
                right:0;
            }

            .inner-top,.inner-bottom{
                background: var(--boxColor);
                position: absolute;
                width: 60%;
                height: 43%;

            }
            
            .inner-top{
                top:0
            }

            .inner-bottom{
                bottom:0;
            }

			.door {
				position: absolute;
				width: 60%;
				height: 16%;
                border-top: 1px  solid rgba(3, 192, 192, 0.836);
                background: var(--boxColor);
				transform-origin: top;
				animation: doorOpen var(--bounceSpeed) infinite linear;
				z-index: s10;

				@keyframes doorOpen {
					0%,
					100% {
						transform: rotateX(145deg);
						box-shadow: 6px 6px 4px rgb(20, 20, 20);
					}
					30%,
					94% {
						transform: rotateX(0deg);
						box-shadow: none;
					}
				}
			}
		}

        .qualities{
            position: absolute;
			transform-style: preserve-3d;


			

            h5{
                position: absolute;
                font-size: 4px;
                border: 1px solid;
				top:6em
            }

			@for $i from 1 through 6{
				h5:nth-child(#{$i}){
					transform: translateZ(#{-2+$i/2}em);
					animation: comeOut1 10s infinite ease-out calc(#{$i *2s});
				}
			}

			@keyframes comeOut {
				0%{
					transform: translateX(0em) rotateY(0deg);
					opacity: 1;
				}

				5%{
					transform: translateX(-27em) rotateY(-60deg);
				}

				20%,25%{
					transform: translateY(-5em) translateX(-27em) rotateY(-60deg) ;
					opacity: 1;
				}
				26%{opacity: 0;}
				

				100%{
					opacity: 0;
					transform: translateX(-27em);
				}
			
			}
        }
	}

	.floor1 {
		position: absolute;
		transform: translate(-50%, -50%) rotateX(90deg);
		top: 1em;

		width: 6em;
		height: 6em;
		background-image: radial-gradient(#0000, #000 90%),
			repeating-conic-gradient(from 45deg, #111 0deg 90deg, #222 90deg 180deg);
		background-size: 100%, 1em 1em;
	}
}

@media screen and (max-width: 750px){
	.Bounce .front h3{
		font-size: 7px;
		
	}
}
