.our-input{
    margin-top: 30px;
    text-align: left;
    width: 100%;


    label{
        font-size: 16px;
        font-weight: 200;
    }

    .inner-our-input{
        display: flex;
        flex-direction: column;
        width: 100%;


        .inner-level-2{
            margin-top: 10px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            

            span{
                font-size: 15px;
                font-weight: 100;
                margin-right: 5px;
            }

            .write{
                color: rgb(234,74,170);
            }
            .good{
                color: green;
            }
            .bad{
                color:red;
            }

            input{
   
                background: transparent;
                border: transparent;
                outline: transparent;
                border-bottom: 1px solid gray;
                font-size: 18px;
                font-weight: 100;
                padding: 5px;
                width: 100%;
            }
            textarea{
                background: transparent;
                outline: transparent;
                border: 1px solid gray;
                font-size: 18px;
                font-weight: 100;
                padding: 5px;
                width: 200px;
                height: 150px;
                border-radius: 5px;
                width: 100%;
                resize: none;
            }

            select{
                width: 200px;
                font-size: 16px;
                background-color: transparent;
                color: white;
                padding: 5px;
                border-radius: 5px;
                border: 1px solid #627597;

                option{
                    background-color: rgb(4,13,33);
                    padding: 20px
                }
            }

            .checkbox-wrapper{
                width: 100%;
                display: flex;
                flex-wrap: wrap;

                .checkbox-item{
                    margin: 5px 25px;
                    width: 120px;
                    display: flex;


                    input{
                        width: 20px;
                        height: 20px;
                    }
                    label{
                        margin-left: 10px;
                        color: white;
                    }
                } 

            }



        }
        button{
            background-color: transparent;
            outline: green;
            border: 1px solid green;
            padding: 5px 7px;
            font-size: 16px;
            color: green;
            border-radius: 5px;
            margin-top: 10px;
            cursor: pointer;
        }

        button:disabled{
            color: #627597;
            border: 1px solid #627597;

        }
    }
}

// @media screen and (min-width: 350px) {
//     .our-input  .inner-our-input .inner-level-2{
//        input{
//            width: 80%;
//        }
//        textarea{
//            width: 80%;
//        }
//     }
// }

@media screen and (min-width: 544px) {
    .our-input{
        .inner-our-input{
            flex-direction: row;
            justify-content: space-between;
        }
    }
}







