.NavItems .navs .nav-item{
    width: 100%;
    border-top: 1px solid rgb(var(--color-text-muted-thin));
    padding: 10px;
    cursor: pointer;
}



.NavItems .navs .nav-item:last-child{
    border-bottom: 1px solid rgb(var(--color-text-muted-thin));
}

.NavItems .navs li:hover{
    color: rgb(var(--color-button))
}

.socials  svg{
    fill: rgb(var(--color-text-muted));
    width: 20px;
    height: 20px;
    margin: 5px;
    cursor: pointer
}
.NavItems .socials  svg:hover{
    fill: rgb(Var(--color-button))
}

