

.smooth-in-logo {
    opacity: 0;
    animation: smooth-in 1s forwards .2s
}

@for $i from 1 through 10{
    .animate-in .nav-item:nth-child(#{$i}){
        opacity: 0;
        animation: bounce-in .7s forwards (#{$i *0.05s}),             
    }
}

@for $i from 1 through 10{
    .animate-in-socials li:nth-child(#{$i}){
        opacity: 0;
        animation: bounce-in .7s forwards (#{($i+13) *0.05s}),             
    }
}

@keyframes smooth-in{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@keyframes bounce-in{
    0%{
        opacity: 0;
        transform: scale(1);
     }

    5%{
        opacity: .1;
        transform: scale(1.5);
        }

    45%{
        opacity: .4;
        transform: scale(0.9);   
        }

    70%{
        transform: scale(1.3);
    }
        
    85%{
        transform: scale(0.95);
    }


    95%{
        transform: scale(1.1);
    }

    100%{
        transform: scale(1);
        opacity: 1;
    }
}