html, body { height: 100%; width: 100%; margin:0;padding:0;}


.App {
  text-align: center;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.app-wrapper{
  width: 100%;
  
}





