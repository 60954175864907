@tailwind base;
@tailwind components;
@tailwind utilities;

/* The color codes here was used to make 
setting the opacity possible. refer to withOpacity of the 
function used to set the color of at the tailwind.config file
*/

@layer base {
	:root {
		--color-text-base: 255, 255, 255;
		--color-text-muted: 214, 206, 255;
		--color-text-muted-thin: 214, 206, 255, 0.1;
		--color-text-inverted: 2, 255, 2;
		--color-fill: 19, 15, 15;
		--color-button: 2, 255, 2;
		--color-button-sec: 236,236,163;
		--color-button-hover: 0, 197, 99;
		--color-background-logo: 6, 7, 7;
		--color-background-general: 29, 29, 29;
		--color-background-nav: 24, 24, 24;
	}

	.Purple-Dim {
		--color-text-base: 137, 2, 179;
		--color-text-muted: 214, 206, 255;
		--color-text-muted-thin: 214, 206, 255, 0.1;
		--color-text-inverted: 255, 199, 14;
		--color-fill: 22, 38, 49;
		--color-button: 255, 199, 14;
		--color-button-hover: 0, 197, 99;
		--color-background-logo: 6, 7, 7;
		--color-background-general: 29, 29, 29;
		--color-background-nav: 19, 19, 19;
		--color-button-sec: 236,236,163;
	}

	.Navy-Dim {
		--color-text-base: 204, 214, 246;
		--color-text-muted: 214, 206, 255;
		--color-text-muted-thin: 214, 206, 255, 0.1;
		--color-text-inverted: 255, 199, 14;
		--color-fill: 22, 38, 49;
		--color-button: 100, 255, 218;
		--color-button-hover: 7, 20, 37;
		--color-background-logo: 6, 7, 7;
		--color-background-general: 17, 34, 64;
		--color-background-nav: 10, 25, 47;
		--color-button-sec: 247,212,214;
	}

	.Light {
		--color-text-base: 107, 3, 121;
		--color-text-muted: 124, 89, 126;
		--color-text-muted-thin: 124, 89, 126, 0.1;
		--color-text-inverted: 255, 199, 14;
		--color-fill: 22, 38, 49;
		--color-button: 154, 47, 255;
		--color-button-hover: 235, 0, 0;
		--color-background-logo: 6, 7, 7;
		--color-background-general: 255, 255, 255;
		--color-background-nav: 233, 233, 233;
		--color-button-sec: 222,60,110;
	}
}

@layer utilities {
	.-pb-2 {
		margin-bottom: -0.15rem;
	}
}

@layer components {
	.top-h2 {
		@apply text-4xl font-semibold font-sans text-left flex;
	}
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
