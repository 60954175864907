.HamburgerOuter {
    width: 42px;
    height: 35px;
    display: flex;
    align-items: center;
    position: relative;
    transition: all 0.2s ease-in-out;
    margin-right: 2%;
    border: 1px solid transparent;
    border-color: rgb(var(--color-button));
    border-radius: 2px;
    z-index: 90;
    
}
.HamburgerMenu {
    background-color: inherit;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.HamburgerMenu div{
    width: 45%;
    height: 1px;
    background-color: rgb(var(--color-button));;
    transition: all 0.3s ease-out; 
    margin: 4px 
}

.HamburgerMenu .middle{
    width: 85%;
}

.RotateTop {
    
    transform: rotate(-37deg) translate(-8px,-2px);
}

.RotateBottom {
    transform: rotate(216deg) translate(8px,-1px)
}

.RotateMiddle{
    transform: translateX(2px);
}



@media (min-width:768px){
    .HamburgerOuter  {
        display: none;
    }
}