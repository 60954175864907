.notification{
 width: 350px;
 height: 250px;
 background-color: rgb(4,13,33);
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: space-between;
 border: 1px solid #0d1f3d;
 border-radius: 5px;
 position: fixed;
 z-index: 5;
 top: 50%;
 left: 50%;
 transform: translate(-50%,-50%);
}

.notification h3{
    border-bottom: 1px solid #0d1f3d;;
    color: green;
    font-size: 25px;
    font-weight: 100;
    padding: 20px;
}

.notification h4{
    font-size: 16px;
    color: #627597;
    padding: 20px;

}

.notification button{
    width: 100px;
    padding: 10px;
    color: green;
    background-color: transparent;
    border: 1px solid green;
    border-radius: 5px;
    margin-bottom: 20px;
    cursor: pointer;
}

.brief-notification{
    animation: comeAndGo 2.5s ease-in  forwards;
}

@keyframes comeAndGo {
    0%,100%{
        right: -100%
    }
    10%{
        right: 3%;
    }

    13%,85%{
        right: 0
    }
    88%{
        right: 3%
    }

}
