.roll-text{
    display: flex;
    min-width: 100%;
    margin-bottom: 7px;

    h2{
        font-weight: 400;
        opacity: 0;
        margin: 0;
    }

}


@for $i from 1 through 60{
    .roll-text h2:nth-child(#{$i}){
        animation: bounce-in .4s forwards (#{$i *0.05s}),             
    }
}

@keyframes rotate-in {
    from{
        transform: rotate(180deg);
    }

    to{
        transform: rotate(360deg);
    }
}
    
@keyframes bounce-in{
    0%{
        opacity: 0;
        transform: scale(1);
     }

    5%{
        opacity: .1;
        transform: scale(1.5);
        }

    45%{
        opacity: .4;
        transform: scale(0.9);   
        }

    70%{
        transform: scale(1.3);
    }
        
    85%{
        transform: scale(0.95);
    }


    95%{
        transform: scale(1.1);
    }

    100%{
        transform: scale(1);
        opacity: 1;
    }
}