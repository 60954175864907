:root {
    --primary: #00a2ff;
}


.preloader{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100%;
height: 100vh;
animation: fade-anime .5s ease-in-out forwards 2.5s
}


.preloader svg{
    fill: none;
    width: 80%;
    animation: fill-anime 1s ease-in-out 2s forwards;
}

.preloader #chikwado path{
    stroke: rgb(var(--color-button));
    stroke-width:2px;
   
}

.preloader svg path:nth-child(1){
    stroke-dasharray: 440px;
    stroke-dashoffset: 440px;
    display: none;
    
}

.preloader svg path:nth-child(2){
    stroke-dasharray: 686px;
    stroke-dashoffset: 686px;
    animation: line-anime 2s ease-in-out forwards 0.5s, scale-anime .2s ease-in-out 1.2s forwards;;
}

.preloader svg path:nth-child(3){
    stroke-dasharray: 720px;
    stroke-dashoffset: 720px;
    animation: line-anime 2s ease-in-out forwards 0.4s;
}
.preloader svg path:nth-child(4){
    stroke-dasharray: 691px;
    stroke-dashoffset: 691px;
    animation: line-anime 2s ease-in-out forwards 0.6s;
}
.preloader svg path:nth-child(5){
    stroke-dasharray: 801px;
    stroke-dashoffset: 801px;
    animation: line-anime 2s ease-in-out forwards;
}
.preloader svg path:nth-child(6){
    stroke-dasharray: 800px;
    stroke-dashoffset: 800px;
    animation: line-anime 2s ease-in-out forwards .2s;
}
  
.preloader svg path:nth-child(7){
    stroke-dasharray: 723px;
    stroke-dashoffset: 723px;
    animation: line-anime 2s ease-in-out forwards .4s;
}

.preloader svg path:nth-child(8){
    stroke-dasharray: 519px;
    stroke-dashoffset: 519px;
    animation: line-anime 2s ease-in-out forwards .6s;
}

.preloader svg path:nth-child(9){
    stroke-dasharray: 519px;
    stroke-dashoffset: 519px;
    animation: line-anime 2s ease-in-out forwards .6s;
}

.preloader .load{
    background-color:  rgb(var(--color-button));
    width: 0%;
    height: 1px;
    border-radius: 20px;
    animation: load-anime 1s ease-in-out 1s forwards;

}

@keyframes line-anime{
    to{
        stroke-dashoffset: 0;
    }
}

@keyframes scale-anime{
    to{
        transform: scale(1.5) translate(-47px,-47px);
    }
}

@keyframes load-anime{
    from {
        width: 0%;
    }
    to{
        width: 70%;
    }
}


@keyframes fill-anime{
    from {
        fill: transparent;;
    }
    to{
        fill:  rgb(var(--color-button));
    }
}

@keyframes fade-anime{
    0% {
        transform: translateY(0);
    }
    50%{
        transform: translateY(-5px);
        opacity: 1;
    }
    100%{
        transform: translateY(-10px);
        opacity: 0;
    }
}