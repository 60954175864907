.project{
    
    svg{
        fill: white;
        width: 25px;
        height: 25px;
        padding: 0;
        margin: 8px;
    }

    .image img{
        filter: grayscale(80%);
        transition: all ease-in-out .4s;
        position: relative;
    }

    .image .layer{
        transition: all ease-in-out .4s;
    }

   

    
}



.project .image:hover img{
    filter: none;
}

.project .image:hover .layer{
    opacity: 0;
}

@media (min-width: 640px){
    .desc{
        width: 150%;
        box-shadow: rgba(0, 0, 0, 0.25) 2px 1px 10px ;
    }
   
}

@media (min-width: 1024px){
    .desc{
        width: 120%
    }
}


